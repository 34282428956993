import CeoImg from "../../assets/Ceo.jpg";

export const teamMembers = [
    {
        image: CeoImg, 
        title: "Purushotham M",
        description: "Business Managing Director and Founder of Company. Purushotham has over 10 years of experience in the industry.",
    },
    {
        image: "https://via.placeholder.com/340", 
        title: "Naveen M",
        description: "Sales and Marketing Director. Naveen M is known for his operational excellence.",
    },
    {
        image: "https://via.placeholder.com/340", 
        title: "Mike Johnson",
        description: "CTO of Company. Mike is an expert in technology and innovation.",
    },
    {
        image: "https://via.placeholder.com/340", 
        title: "Emily Davis",
        description: "CFO of Company. Emily oversees all financial operations.",
    },
];